$row-details-spacer: map-get($spacers, 1) !default;

@mixin table-actions-th-td {
  th, td {
    &.fit, &.actions, &.hide-header {
      white-space: nowrap;
      width: 1%;

      &.wrap {
        white-space: wrap;
      }
    }

    &.hide-header {
      > * {
        display: none;
      }
    }
  }
}

@mixin table-actions-td {
  td {
    &.actions {
      text-align: right;
    }
  }
}

table {

  &.table-fixed {
    table-layout: fixed !important;
  }

  &:not([class*='b-table-stacked']) {
    @include table-actions-th-td;
    @include table-actions-td;
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      &.b-table-stacked-#{$breakpoint} {
        @include table-actions-th-td;
        @include table-actions-td;
      }
    }
  }

  tr {
    &.no-top-border {
      td, th {
        border-top: 0;
      }
    }
  }

  td, th {
    &.no-border {
      border: 0;
    }

    &.no-top-border {
      border-top: 0;
    }
  }

  td {
    .btn {
      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $line-height-base, $btn-border-radius-sm);
    }
  }

  .row-details-button-container {
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    gap: $row-details-spacer;
    justify-content: flex-start;

    button .flex-icon-content {
      text-wrap: nowrap;
    }
  }
}
