.nav-tabs {
  .nav-link {
    height: 100%; // Ensure each tab has full line height
    border-color: $nav-tabs-border-color !important;

    &.active {
      background-color: $primary !important;
      border-color: $primary !important;
      color: $white !important;
    }
  }
}

.tabs {
  .tab-content {
    padding: map-get($spacers, 2);
    border: 1px solid $nav-tabs-border-color;
    border-top: 0;
    @include border-bottom-radius($border-radius);
    @include box-shadow($box-shadow-sm);
  }
}
