@import '~select2-theme-bootstrap4/src/select2-bootstrap';

// Overwrites for CustomOptions component
// Done here due to the massive amount of variables requirement from the select2 theme, which are already available here
.custom-option-component {
  .select2-container--bootstrap {
    &.select2-container--open {
      .select2-selection .select2-selection__arrow b {
        border-color: transparent transparent $s2bs-dropdown-arrow-color transparent;
        border-width: 0 $s2bs-caret-width-base $s2bs-caret-width-base $s2bs-caret-width-base;
      }

      &.select2-container--below {
        .select2-selection {
          @include border-bottom-radius($s2bs-border-radius-base);
          border-bottom-color: $s2bs-input-border-focus;
        }
      }

      &.select2-container--above {
        .select2-selection {
          @include border-top-radius($s2bs-border-radius-base);
          border-top-color: $s2bs-input-border-focus;
        }
      }
    }
  }
}

.select2-container--bootstrap .select2-selection--multiple.form-control-sm .select2-selection__clear,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
.form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
  margin-top: 0.125rem;
}

table.table {
  .select2-selection .select2-selection__rendered {
    // To prevent click event toggling table checkboxes
    pointer-events: none;
  }
}
