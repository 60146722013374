$content-margin: .5rem;

.exception-page {
  margin: auto;
  max-width: 25rem;

  > div {
    margin: auto;
    width: 100%;
    text-align: center;

    &.icon {
      &.system-error {
        color: map_get($theme-colors, danger);
      }

      font-size: 4rem;
      margin-bottom: $content-margin;
    }

    &.content {
      margin-bottom: $content-margin;
    }

    &.back {
      margin-bottom: $content-margin;
    }

    &.code {
      margin-top: 1rem;
      font-size: x-small;
    }
  }
}
