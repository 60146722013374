@use 'sass:math';

$grid-map: (
  md: 2, // This breakpoint also enables the wide layout
  xl: 3,
  xxl: 4
);
$simple-grid-margin: map-get($spacers, 1);

.d-grid {
  display: grid;
}

.grid-layout {
  margin-bottom: $spacer;
  display: grid;
  gap: map-get($spacers, 2);
  grid-auto-rows: minmax(auto, auto);
  grid-auto-flow: dense;
  max-width: 100%;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  &.narrow-gap {
    gap: map-get($spacers, 2) * 0.5;
  }

  @each $breakpoint, $repeat in $grid-map {
    @include media-breakpoint-up($breakpoint) {
      grid-template-columns: repeat($repeat, minmax(0, 1fr));
      @for $i from 2 through $repeat {
        @if $i != $repeat {
          &.grid-count-#{$i} {
            grid-template-columns: repeat($i, minmax(0, 1fr));
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .wide {
      grid-column-end: span 2;
    }
  }

  .fw {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .card, > div > fieldset.form-group {
    margin-bottom: 0;
  }
}

.simple-grid-layout {
  display: flex;
  flex-wrap: wrap;
  margin: 0 $simple-grid-margin * -1 $simple-grid-margin * -2;

  > div, > li {
    margin: 0 $simple-grid-margin $simple-grid-margin * 2;
    flex-basis: 1px;
    flex-grow: 1;
    flex-shrink: 0;

    min-width: calc(#{percentage(0.25)} - #{$simple-grid-margin * 2});
    max-width: calc(#{percentage(0.25)} - #{$simple-grid-margin * 2});

    @include media-breakpoint-down(xl) {
      min-width: calc(#{percentage(math.div(1, 3))} - #{$simple-grid-margin * 2});
      max-width: calc(#{percentage(math.div(1, 3))} - #{$simple-grid-margin * 2});
    }

    @include media-breakpoint-down(lg) {
      min-width: calc(#{percentage(0.5)} - #{$simple-grid-margin * 2});
      max-width: calc(#{percentage(0.5)} - #{$simple-grid-margin * 2});
    }

    @include media-breakpoint-down(sm) {
      min-width: calc(#{percentage(1)} - #{$simple-grid-margin * 2});
      max-width: calc(#{percentage(1)} - #{$simple-grid-margin * 2});
    }
  }
}

ul.simple-grid-layout {
  list-style-position: inside;
}

.modal-body {
  .simple-grid-layout {
    > div {
      @include media-breakpoint-up(xl) {
        min-width: calc(#{percentage(math.div(1, 3))} - #{$simple-grid-margin * 2});
        max-width: calc(#{percentage(math.div(1, 3))} - #{$simple-grid-margin * 2});
      }
    }
  }
}

.grid-col-span-2 {
  grid-column: span 2 / span 2;
}
