.nowrap {
  white-space: nowrap;
}

.nl2br {
  white-space: pre-line;
}

ul {
  list-style-position: outside;
}

.ellipsis {
  @include ellipsis();
}

.strikethrough {
  text-decoration: line-through;
}
