.main-header {
  .navbar-nav {
    &.navbar-logo {
      .nav-item {
        position: relative;

        img {
          position: absolute;
          top: -22px;
          left: 0;
          right: 0;
          z-index: -1;
        }
      }
    }

    &.navbar-login .btn:not(:active):not(:hover) {
      background-color: $light;
    }
  }
}

@include media-breakpoint-down(sm) {
  .main-header {
    .navbar-nav {
      &.navbar-menu-title, &.navbar-logo, &.navbar-login {
        flex-basis: unset;
        flex-grow: 0;
        flex-shrink: 0;
      }

      &.navbar-logo {
        .nav-item img {
          margin: unset;
        }
      }

      &.navbar-login {
        flex-grow: 1;
      }
    }
  }
}

.gray-header {
  box-shadow: none;
  margin-bottom: 0;

  .card-header {
    border-width: $input-border-width;
    border-color: $input-border-color;
    border-radius: $input-border-radius $input-border-radius 0 0;
    border-style: solid;
    padding: $spacer * 0.25 $spacer * 0.5;
  }

  .card-body {
    border-width: $input-border-width;
    border-top-width: 0;
    border-color: $input-border-color;
    border-radius: 0 0 $input-border-radius $input-border-radius;
    border-style: solid;
  }
}
