.clickable {
  cursor: pointer;
}

.card {
  .card-header.bg-primary {
    a:not(:hover) {
      color: white;
    }
  }

  &.clickable {
    &:hover {
      box-shadow: $box-shadow !important;
    }
  }
}

.page-loader-blocker {
  position: fixed;
  pointer-events: all;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparentize(white, 0.2);
  backdrop-filter: blur(2px);

  // Ensure the blocked is placed over the select2 widget when no modal is opened
  z-index: 1100;
}

body.modal-open {
  .page-loader-blocker {
    // Ensure the blocker is placed under the modal when opened
    z-index: 1039;
  }
}

.animate-rotation {
  transition-duration: 0.5s;
  transition-property: transform;
}

body:not(.modal-open) > .select2-container > .select2-dropdown {
  // Ensure an open select2 dropdown is positioned behind the top navbar when scrolling
  z-index: 1036;
}

@each $name, $color in $theme-colors {
  .text-hover-#{$name} {
    @include transition($btn-transition);

    &:hover {
      color: $color;
    }
  }
}

.sticky-controls, .sticky-header {
  margin-left: $negative-page-gutter;
  margin-right: $negative-page-gutter;
  margin-bottom: map_get($spacers, 2);
  padding: 0 $page-gutter map_get($spacers, 2);
  position: sticky;
  top: $main-header-height;
  z-index: 199;
  border-bottom: $main-header-bottom-border;
  background-color: $white;
  box-shadow: unquote('0 3px 3px ' + rgba($black, 0.12));
}

.sticky-header {
  padding-top: calc(#{$spacer * 0.5} + 7.5px);
  margin-top: calc(-#{$spacer * 0.5} - 7.5px);
}

.popover {
  &.popover-large {
    max-width: 50vw;
    width: 500px;
  }

  .popover-image {
    max-width: 100%;
  }

  &.equipment-info {
    margin-left: $popover-arrow-height + 1.25rem;
  }

  &.elevation-popover {
    // Ensure the arrow is rendered over the menu bar
    z-index: 1037;
  }
}

.b-skeleton.b-skeleton-button.small {
  line-height: $line-height-sm;
  @include font-size($small-font-size);
}

.load-button {
  position: relative;

  .icon {
    position: absolute;
    left: 0;
    right: 0;
  }

  .text.loading {
    color: transparent;
  }
}

.manual-pointer-block {
  pointer-events: none;
}
