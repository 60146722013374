$separator-width: 1px;

.login-prompt {
  max-width: 40rem;

  p {
    margin-bottom: map-get($spacers, 2);
  }

  .separator {
    display: flex;
    width: 100%;
    margin: map-get($spacers, 2) 0;

    > div {
      &:not(.content) {
        position: relative;

        &::before {
          content: ' ';
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: calc(50% - #{$separator-width * 0.5});
          background-color: black;
          height: $separator-width;
        }
      }

      &.content {
        margin: 0 map-get($spacers, 3);

        span {
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }

  .remember-me {
    .custom-control-label {
      &::before {
        left: -1.75rem;
      }

      &::after {
        left: calc(-1.75rem + 2px);
      }
    }
  }
}
