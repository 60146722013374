img {
  .user-profile div.profile-img & {
    max-width: 100%;
    max-height: 20vh;
    display: block;
    margin: auto;

    @include media-breakpoint-up(md) {
      max-height: unset;
      max-width: calc(100% - 2rem);
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .vich-image & {
    box-shadow: map-get($elevations, 1) !important;
  }

  &.fit-container {
    max-width: 100%;
    max-height: 100%;
  }
}
