$fade-speed: .2s;

.fade, .router-fade {
  &-enter-active, &-leave-active {
    transition: opacity $fade-speed;
  }

  &-enter, &-leave-to {
    opacity: 0;
  }
}

.router-fade {
  &-enter-active {
    transition-delay: $fade-speed;
  }
}
