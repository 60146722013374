// Define theme colors
// Nanolab colors
$red: #c60d31;
$green: #28a745;
$blue: #0693e3;
$yellow: #ffc107;
$white: #ffffff;
$gray: #767676;
$black: #222;
$purple: #9746fc;
$orange: #FF9900;

// Overwrite bootstrap colors
$primary: $blue;
$secondary: $gray;
$success: lighten($green, 5%);
$danger: lighten($red, 10%);
$warning: lighten($yellow, 5%);
$info: lighten($blue, 10%);

// Admin LTE sidebar colors
$sidebar-dark-bg: $primary;
$sidebar-dark-color: $white;
$sidebar-dark-hover-bg: $white;
$sidebar-dark-hover-color: $black;

$sidebar-dark-submenu-color: $white;
$sidebar-dark-submenu-hover-bg: $white;
$sidebar-dark-submenu-hover-color: $black;
$sidebar-dark-submenu-active-bg: $white;
$sidebar-dark-submenu-active-color: $black;

// Admin LTE main background color
$main-bg: $white;
