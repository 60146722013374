.bottom-line {
  > div {
    padding-bottom: map_get($spacers, 1);
    padding-top: map_get($spacers, 1);
    border-top: 1px solid $gray-500;

    &:first-child {
      border: 0;
      padding-top: 0;
    }
  }
}

.alert {
  margin-bottom: map-get($spacers, 2);

  a.btn {
    text-decoration: none;
  }

  &.less-padding {
    padding: map-get($spacers, 1) map-get($spacers, 2);
  }
}

.flex-1px-basis {
  flex-basis: 1px !important;
}

.flex-input-basis {
  flex-basis: 10rem !important;
}

@each $size, $length in $spacers {
  .flex-gap-#{$size} {
    gap: $length;
  }
}
