form {
  .form-group-save-button {
    .form-group-inner {
      $button-spacers: map_get($spacers, 1) * 0.5;

      display: flex;
      flex-direction: row-reverse;
      margin-left: -1 * $button-spacers;
      margin-right: -1 * $button-spacers;

      > * {
        margin-left: $button-spacers;
        margin-right: $button-spacers;
      }
    }
  }

  label, legend, div.checkbox-required {
    font-weight: bold;

    &.required:not(.custom-control-label) {
      &::after {
        content: '*';
        color: map-get($theme-colors, danger);
        padding-left: $spacer * 0.25;
      }
    }
  }

  textarea {
    min-height: 3rem;
    max-height: 75vh; // Large text area scroll workaround, see #703
  }

  .custom-checkbox {
    .custom-control-input[disabled], .custom-control-input:disabled {
      & ~ .custom-control-label {
        cursor: not-allowed;
      }
    }

    label.custom-control-label {
      font-weight: normal;
    }
  }

  .custom-file {
    label.custom-file-label {
      @include ellipsis();
    }
  }

  .custom-radio {
    &:first-of-type {
      padding-top: add($input-padding-y, $input-border-width);
    }
  }
}

.custom-checkbox {
  .custom-control-input:not(.disabled):not(:disabled) ~ .custom-control-label {
    cursor: pointer;
  }
}

label.col-form-label {
  font-weight: bold;
}

.custom-select:not(.disabled):not(:disabled) {
  cursor: pointer;
}

.select2-hidden-accessible {
  left: 0;
}

input, textarea {
  &:disabled {
    cursor: not-allowed;
  }
}

div.vc-chrome {
  box-shadow: none !important;
  border: $input-border-width solid $input-border-color;
  overflow: hidden;
  @include border-radius($input-border-radius);

  > * {
    @include border-top-radius($input-border-radius);
  }
}

.checkbox-form-row {
  .col-form-label {
    padding-bottom: 0;
  }
}
