@mixin modal-fill() {
  max-width: 100% !important;
  margin: $modal-dialog-margin;

  .card:last-of-type {
    margin-bottom: 0;
  }
}

.modal-dialog {
  &.fill {
    @include media-breakpoint-between(sm, lg) {
      @include modal-fill();
    }
  }

  &.fill-always {
    @include modal-fill();
  }
}
