$sidebar-brand-padding: 0.5rem;

.main-sidebar {
  .sidebar-brand-link {
    padding: $sidebar-brand-padding;

    .sidebar-brand-image {
      height: calc(#{$main-header-height} - #{2 * $sidebar-brand-padding});
      width: 100%;
    }
  }
}
