// This file contains common vars that combine other variables into usable stuff, or cannot be retrieved elsewhere
// Easy for use in Vue components as it already defines the most used variables/mixins/functions from bootstrap
// Based on the default layout entry point

@import 'colors';

// Bootstrap overrides
$grid-breakpoints: (
    xxs: 0,
    xs: 380px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1500px
);

$card-spacer-y: 0.5rem;
$card-spacer-x: 0.5rem;
$form-group-margin-bottom: 0.25rem;

@import '~bootstrap/scss/functions';
@import '~@drenso/layout-shared/dist/admin-lte/build/scss/bootstrap-variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~@drenso/layout-shared/dist/admin-lte/build/scss/variables';
@import '~@drenso/layout-shared/dist/admin-lte/build/scss/variables-alt';
@import '~@drenso/layout-shared/dist/mixins/text';

// Select 2 overrides
$s2bs-dropdown-link-hover-bg: $gray-400;

// Custom variables
$negative-page-gutter: calc(-#{$content-padding-x} - #{$grid-gutter-width * 0.5}); // Content wrapper + container gutter
$page-gutter: calc(#{$content-padding-x} + #{$grid-gutter-width * 0.5}); // Content wrapper + container gutter
$ticker-height: $carousel-indicator-height + ($carousel-indicator-hit-area-height * 2);
$extended-darken-factor: 15%;
