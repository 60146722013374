@import 'colors';
@import 'variables';
@import 'transitions';

///////////////////////////////////////////////////////////////
/// Vendor imports
///////////////////////////////////////////////////////////////

// Shared layout, which includes bootstrap
@import '~@drenso/layout-shared';

// Add custom Bootstrap Vue styles
@import '~bootstrap-vue/src/index.scss';

// Overlay scrollbars plugin
@import '~overlayscrollbars/css/OverlayScrollbars.css';

// Select2
@import '~select2/src/scss/core';
@import 'select2/bootstrap4-theme';

///////////////////////////////////////////////////////////////
/// Application overwrites
///////////////////////////////////////////////////////////////
@import 'vich/form';

@import 'components/accordion';
@import 'components/exception';
@import 'components/forms';
@import 'components/gap';
@import 'components/grid_layout';
@import 'components/header';
@import 'components/img';
@import 'components/interaction';
@import 'components/login';
@import 'components/misc';
@import 'components/modal';
@import 'components/overlay_scrollbars';
@import 'components/sidemenu';
@import 'components/tables';
@import 'components/tabs';
@import 'components/text';
@import 'components/tooltip';
