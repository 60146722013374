.vich-image {
  display: flex;
  flex-direction: column;

  .preview {
    align-self: center;
    padding: map_get($spacers, 2);

    img {
      max-width: 100%;
      max-height: 25vh;
    }
  }
}
